import React, { useCallback } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import { Icon } from "@iconify/react";
import sunFill from "@iconify-icons/eva/sun-fill";
import moonFill from "@iconify-icons/eva/moon-fill";
import shoppingCartIconOutline from "@iconify-icons/eva/shopping-cart-outline";
import shoppingCartIconFill from "@iconify-icons/eva/shopping-cart-fill";

import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "~/redux/slices/dark-mode";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
import { MIconButton } from "~/@material-extend";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    margin: "0 8px",
    cursor: "pointer",
  },
}));

// ----------------------------------------------------------------------

function OrderHistory({ primaryColor }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const { darkMode } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const onToggleTheme = useCallback(() => dispatch(toggleTheme()), [dispatch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <MenuIcon onClick={handleClick}></MenuIcon>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem
          sx={{ opacity: 0.7 }}
          onClick={() => {
            history.push({
              pathname: "/orders",
              state: { primaryColor: primaryColor },
            });
          }}
        >
          <img src={orderHistory} width={20} height={20} />
          &nbsp;&nbsp; Orders
        </MenuItem> */}

        <MIconButton
          onClick={() => {
            history.push({
              pathname: "/orders",
              state: { primaryColor: primaryColor },
            });
          }}
          color={darkMode ? "primary" : "default"}
          sx={{ ml: 1, mr: 2, p: 0 }}
        >
          <Icon
            icon={darkMode ? shoppingCartIconFill : shoppingCartIconOutline}
            width={20}
            height={20}
          />
          &nbsp;&nbsp;
          <Typography variant="subtitle1">Orders</Typography>
        </MIconButton>

        <br />

        {/* <MIconButton
          onClick={onToggleTheme}
          color={darkMode ? "primary" : "default"}
          sx={{ ml: 1, mr: 2, p: 0 }}
        >
          <Icon icon={darkMode ? sunFill : moonFill} width={20} height={20} />
          &nbsp;&nbsp;
          <Typography variant="subtitle1">
            {darkMode ? "Light Mode" : "Dark Mode"}
          </Typography>
        </MIconButton> */}
      </Menu>
    </div>
  );
}

export default OrderHistory;
