import React, { useState } from "react";
import { makeStyles, alpha } from "@material-ui/core/styles";
import {
  TextField,
  AppBar,
  Toolbar,
  Container,
  InputAdornment
} from "@material-ui/core";
import clsx from "clsx";
import SearchIcon from "@material-ui/icons/Search";
import OrderHistory from "../../orders/OrderHistory";
import ScrollToTopValue from "~/views/common/ScrollToTopValue";

const APP_BAR_MOBILE = 12;
const APP_BAR_DESKTOP = 48;
const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none"
  },
  toolbar: {
    height: APP_BAR_MOBILE,
    transition: theme.transitions.create(["height", "background-color"], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter
    }),
    [theme.breakpoints.up("md")]: {
      height: APP_BAR_DESKTOP
    }
  },
  toolbarContainer: {
    lineHeight: 0,
    width: "52.5%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "7px",
      paddingRight: "7px",
      width: "100%"
    }
  },
  toolbarShadow: {
    left: 0,
    right: 0,
    bottom: 0,
    height: 24,
    zIndex: -1,
    content: "''",
    margin: "auto",
    borderRadius: "50%",
    position: "absolute",
    width: `calc(100% - 48px)`,
    boxShadow: theme.shadows[25].z8
  },
  desktopMenu: {
    "& > * ": {
      color: theme.palette.text.primary,
      marginRight: `${theme.spacing(5)} !important`
    }
  },
  isDesktopActive: {
    color: `${theme.palette.primary.main} !important`
  },
  mobileMenu: {
    color: theme.palette.text.secondary
  },
  isMobileActive: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    )
  },
  isHome: { color: theme.palette.common.white }
}));

function SearchBar({ menu, primaryColor, handleSearch }) {
  const classes = useStyles();
  const [typing, setTyping] = useState(false);
  const [typingTimeOut, setTypingTimeOut] = useState(0);

  // *******************************Search Bar*******************************
  const onSearch = searchQuery => {
    if (searchQuery === "") {
      handleSearch(menu);
      ScrollToTopValue(0);
      return;
    }
    if (!menu.categories.length) {
      return;
    }
    let newMenu = {};
    for (let index = 0; index < menu.categories.length; index++) {
      const category = menu.categories[index];
      if (typeof newMenu.categories === "undefined") {
        newMenu.categories = [];
      }
      if (
        category.label
          .toLowerCase()
          .replace(/\s/g, "")
          .includes(searchQuery)
      ) {
        newMenu.categories.push(category);
      } else {
        let newCategory = {};
        newCategory.items = [];
        for (
          let itemIndex = 0;
          itemIndex < category.items.length;
          itemIndex++
        ) {
          const item = category.items[itemIndex];
          if (
            item.label
              .toLowerCase()
              .replace(/\s/g, "")
              .includes(searchQuery)
          ) {
            newCategory.items.push(item);
          }
        }
        if (newCategory.items.length) {
          newCategory.label = category.label;
          newCategory._id = category._id;
          newCategory.discount = category.discount;
          newCategory.order = category.order;
          newCategory.image = category.image;
          newMenu.categories.push(newCategory);
        }
      }
    }
    handleSearch(newMenu);
    ScrollToTopValue(305);
  };
  // *******************************Search Bar*******************************

  return (
    <AppBar
      color="inherit"
      className={clsx(classes.root, { [classes.onScroll]: "" })}
    >
      <Toolbar disableGutters className={classes.toolbar}>
        <Container maxWidth="lg" className={classes.toolbarContainer}>
          <TextField
            sx={{
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              borderRadius: ".5rem"
            }}
            id="outlined-basic"
            variant="outlined"
            placeholder="Search for a Dish"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            onChange={event => {
              if (typingTimeOut) {
                clearTimeout(typingTimeOut);
              }
              setTyping(false);
              setTypingTimeOut(
                setTimeout(() => {
                  onSearch(event.target.value.toLowerCase().replace(/\s/g, ""));
                }, 400) //Adding a delay so that user can type smoothly.
              );
            }}
          />
          {/* <OrderHistory primaryColor={primaryColor} /> */}
        </Container>
      </Toolbar>
    </AppBar>
  );
}

export default SearchBar;
